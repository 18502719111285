import React from 'react';

const Admin = () => {
    return (
        <div>
            admin
        </div>
    );
};

export default Admin;