import React, {memo, useEffect, useRef, useState} from 'react';
import {Bar, Line} from "react-chartjs-2";
import {
    getTop30BrandsCurrentAndPrevYear,
    getTop30BrandsCurrentAndPrevYearLCV,
    getTop30BrandsCurrentAndPrevYearPC,
    getTop30ModelsCurrentAndPrevYears,
    getTop30ModelsCurrentAndPrevYearsLCV,
    getTop30ModelsCurrentAndPrevYearsPC
} from "../../../http/dataForPdf";
import cls from './ChartBarTop30Models.module.scss'
import Spinner from "../../Ui/Spinner/Spinner";
import pc from "../../../pc.png";
import lcv from "../../../lcv.png";


function addBrandNameAndModelName(data) {

    data.data.forEach((el) => {

        el.model_name = data.included.models.find((f) => f.id === el.model_id).name
        el.brand_id = data.included.models.find((f) => f.id === el.model_id).brand_id
    })
    data.data.forEach((el) => {
        el.brand_name = data.included.brands.find((f) => f.id === el.brand_id).name
    })

    return data
}

function transformData(data) {
    const transformedData = [];

    // Создаем массив уникальных годов
    const years = [...new Set(data.map(item => item.year))];

    data.forEach(item => {
        const existingItem = transformedData.find(element =>
            element.brand_id === item.brand_id &&
            element.brand_name === item.brand_name &&
            element.model_id === item.model_id &&
            element.model_name === item.model_name
        );

        if (existingItem) {
            existingItem[item.year] = item.value;
        } else {
            const newItem = {
                brand_id: item.brand_id,
                brand_name: item.brand_name,
                model_id: item.model_id,
                model_name: item.model_name
            };

            // Устанавливаем значение по умолчанию 0 для всех годов
            years.forEach(year => {
                newItem[year] = '0';
            });

            newItem[item.year] = item.value;
            transformedData.push(newItem);
        }
    });

    // Добавляем поле "year_difference" со значением разницы между 2023 и 2022 годами
    transformedData.forEach(item => {
        if (item["2024"] !== 0 && item["2023"] !== 0) {
            item.year_difference = item["2024"] - item["2023"];
            item.percentage_difference = (((item["2024"] - item["2023"]) / Math.abs(item["2023"])) * 100).toFixed(2);
        } else {
            item.year_difference = (item["2024"] !== 0) ? item["2024"] : 0;
            item.percentage_difference = (item["2023"] === 0) ? 100 : 0;
        }
    });

    // Заменяем значения "Infinity" на "100"
    transformedData.forEach(item => {
        for (const year in item) {
            if (item[year] === "Infinity") {
                item[year] = "100";
            }
        }
    });

    // Сортируем массив по значению "2023"
    transformedData.sort((a, b) => b["2024"] - a["2024"]);

    return transformedData;
}

const ChartBarTop30Models = ({car_type, getLastMonthDate, getRefToPdf}) => {
    const [dataForChart, setDataForChart] = useState(null)
    const [dataForTable, setDataForTable] = useState(null)

    const ref = useRef(null)

    let dataChart = {
        labels: [],
        datasets: [{
            label: 'Данные',
            data: [],
            backgroundColor: ['#1d81a2'],
            fill: false,

        }]
    };

    function setDataToDataChart(data) {
        data.slice(0, 30).forEach((el) => {
            dataChart.labels.push(el.brand_name + ' ' + el.model_name)

            dataChart.datasets[0].data.push(el['2024'])
        })
        setDataForChart(dataChart)
    }


    useEffect(() => {
        setDataForTable(null)
        if (car_type === 'pc') {
            getTop30ModelsCurrentAndPrevYearsPC().then((data) => {
                let oldData = addBrandNameAndModelName(data.data)
                let newData = transformData(oldData.data)
                setDataForTable(newData)
                setDataToDataChart(newData)
                setTimeout(() => {
                    getRefToPdf(ref, 'ChartBarTop30ModelsPC')
                }, 2000)
            })
        }
        if (car_type === 'lcv') {
            getTop30ModelsCurrentAndPrevYearsLCV().then((data) => {
                let oldData = addBrandNameAndModelName(data.data)
                let newData = transformData(oldData.data)
                setDataForTable(newData.slice(0, 20))
                setDataToDataChart(newData.slice(0, 20))
                setTimeout(() => {
                    getRefToPdf(ref, 'ChartBarTop30ModelsLCV')
                }, 2000)
            })
        }
        if (car_type === 'all') {
            getTop30ModelsCurrentAndPrevYears().then((data) => {
                let oldData = addBrandNameAndModelName(data.data)
                let newData = transformData(oldData.data)
                setDataForTable(newData)
                setDataToDataChart(newData)
                setTimeout(() => {
                    getRefToPdf(ref, 'ChartBarTop30ModelsALL')
                }, 2000)
            })
        }
        return () => {
            setDataForTable(null)
        }

    }, [car_type])

    return (
        <div ref={ref}>
            {
                car_type === 'pc'
                    ? <div className={cls.title}>
                        <h2> Рынок легковых автомобилей БАА <br/>
                            <span>Топ 30 моделей январь-{getLastMonthDate().toLocaleDateString('ru-RU', {
                                year: 'numeric',
                                month: 'long',
                            })}</span>
                        </h2>
                        <div>
                            <i>{getLastMonthDate().toLocaleDateString('ru-RU', {year: 'numeric', month: 'long',})}</i>
                            <span>
                                 <img src={pc} alt="logo"/>
                            </span>

                        </div>
                    </div>
                    : ''
            }
            {
                car_type === 'lcv'
                    ? <div className={cls.title}>
                        <h2> Рынок легких коммерческих автомобилей БАА <br/>
                            <span>Топ 20 моделей январь-{getLastMonthDate().toLocaleDateString('ru-RU', {
                                year: 'numeric',
                                month: 'long',
                            })}</span>
                        </h2>
                        <div>
                            <i>{getLastMonthDate().toLocaleDateString('ru-RU', {year: 'numeric', month: 'long',})}</i>
                            <span>
                                 <img src={lcv} alt="logo"/>
                            </span>

                        </div>
                    </div>
                    : ''
            }
            {
                car_type === 'all'
                    ? <div className={cls.title}>
                        <h2> Рынок транспортных средств БАА <br/>
                            <span>Топ 30 моделей январь-{getLastMonthDate().toLocaleDateString('ru-RU', {
                                year: 'numeric',
                                month: 'long',
                            })}</span>
                        </h2>
                        <div>
                            <i>{getLastMonthDate().toLocaleDateString('ru-RU', {year: 'numeric', month: 'long',})}</i>
                            <span>
                                <img src={pc} alt="logo"/>
                                <img src={lcv} alt="logo"/>
                            </span>

                        </div>
                    </div>
                    : ''
            }
            {dataForTable
                ? <div className={cls.content}>
                    <div className={cls.table}>
                        <div className={cls.header}>
                            <div>№</div>
                            <div></div>
                            <div>Бренд</div>
                            <div>Модель</div>
                            <div>2023</div>
                            <div>2024</div>
                            <div>YoY</div>
                        </div>
                        {
                            dataForTable.slice(0, 30).map((el, index) =>
                                <div key={index} className={cls.row}>
                                    <div>{index + 1}</div>
                                    <div>
                                        <span
                                            className={el.year_difference > 0 ? cls.green : cls.red}
                                        >
                                            {el.year_difference >= 0
                                                ? el.year_difference === 0 ? '' : '+' + el.year_difference
                                                : el.year_difference
                                            }
                                        </span>
                                    </div>
                                    <div>{el.brand_name}</div>
                                    <div>{el.model_name}</div>
                                    <div>{el[2023]}</div>
                                    <div>{el[2024]}</div>
                                    <div className={cls.rate}>
                                            <span className={cls.red}>
                                                {
                                                    +el.percentage_difference > 0 && <p>{el.percentage_difference}%</p>
                                                }
                                                {
                                                    +el.percentage_difference < 0 &&
                                                    <i style={{width: String(el.percentage_difference).replace('-', '') + '%'}}></i>
                                                }

                                            </span>
                                        <span className={cls.green}>

                                                {
                                                    +el.percentage_difference < 0 && <p>{el.percentage_difference}%</p>
                                                }
                                            {
                                                +el.percentage_difference > 0 &&
                                                <i style={{width: String(el.percentage_difference).replace('-', '') + '%'}}></i>
                                            }
                                            </span>
                                        {/*<span*/}
                                        {/*    className={el.growth_rate < 0 ? cls.red : cls.green}*/}
                                        {/*>*/}
                                        {/*    <p style={{width:'100%',height:'18px', display:'flex',justifyContent:'center',alignItems:'center'}}><span>{el.growth_rate}%</span></p>*/}

                                        {/*    <i style={{width: el.growth_rate.replace('-', '') + '%'}}></i>*/}
                                        {/*</span>*/}
                                    </div>
                                    {/*<div>*/}
                                    {/*    <span*/}
                                    {/*        className={el.percentage_difference < 0 ? cls.red : cls.green}*/}
                                    {/*    >*/}

                                    {/*          <p style={{width:'100%',height:'18px', display:'flex',justifyContent:'center',alignItems:'center'}}><span>{el.percentage_difference}%</span></p>*/}

                                    {/*        <i style={{*/}
                                    {/*            width: el.percentage_difference*/}
                                    {/*                ? String(el.percentage_difference).replace('-', '') + '%'*/}
                                    {/*                : String(el.percentage_difference)*/}
                                    {/*        }}></i>*/}
                                    {/*    </span>*/}
                                    {/*</div>*/}
                                </div>
                            )
                        }

                        <div className={cls.footer}>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div>2023</div>
                            <div>2024</div>
                            <div>YoY</div>
                        </div>
                    </div>
                    <div className={cls.chart}>
                        <Bar style={{width: '100px', height: '100%'}} data={dataForChart} options={{
                            animation: false,
                            scales: {
                                x: {
                                    grid: {
                                        display: true, // устанавливаем значение false для отключения сетки по оси X
                                    },
                                    ticks: {
                                        autoSkip: false,
                                        maxRotation: 90,
                                        minRotation: 90,
                                        offset: -100,
                                        beginAtZero: false,
                                        precision: 0,
                                        source: 'auto',
                                        crossAlign: 'far',
                                        align: 'center',
                                    },
                                },
                                y: {
                                    grid: {
                                        display: true, // устанавливаем значение false для отключения сетки по оси Y
                                    },
                                },
                            },
                            indexAxis: 'x',
                            plugins: {
                                tooltip: {
                                    displayColors: false,
                                    titleColor: '#fff',
                                    enabled: true,
                                },
                                datalabels: {
                                    display: true, // включаем отображение меток данных
                                    weight: 'bold',
                                    color: '#000',
                                    anchor: 'end', // Устанавливаем якорь для меток данных
                                    align: 'top', // Устанавливаем выравнивание меток данных
                                    offset: 0, // Устанавливаем смещение меток данных вверх

                                },
                                legend: {
                                    display: true,
                                    labels: {
                                        font: {
                                            size: 0
                                        },
                                    },
                                    title: {
                                        display: false,
                                        text: 'Продажи по маркам, Только Март 2024',
                                        color: 'goldenrod',
                                        font: {
                                            size: 24
                                        }
                                    },
                                }
                            }
                        }}></Bar>
                    </div>
                </div>
                : <div className={cls.spinner_block}><Spinner/></div>}
        </div>

    );
};

export default memo(ChartBarTop30Models);

