import React, {memo, useEffect, useRef, useState} from 'react';
import {Line} from "react-chartjs-2";
import cls from './ChartLine5YearByMonth.module.scss'
import {
    getCarsInTheLast5YearsByMonth,
    getCarsInTheLast5YearsByMonthLCV,
    getCarsInTheLast5YearsByMonthPC
} from "../../../http/dataForPdf";
import Spinner from "../../Ui/Spinner/Spinner";
import pc from "../../../pc.png";
import lcv from "../../../lcv.png";

function formatMonthInRussian(dateString) {
    let [year, month] = dateString.split("-");
    let date = new Date(parseInt(year), parseInt(month) - 1);
    let dateFormatter = new Intl.DateTimeFormat('ru-RU', {month: 'long'});
    let monthInRussian = dateFormatter.format(date);
    return monthInRussian.toLowerCase() + "-" + year;
}


const ChartLine5YearByMonth = ({getLastMonthDate,getRefToPdf, car_type, numericPrevMonth}) => {
    const [dataForChart, setDataForChart] = useState(null)
    const ref = useRef(null)
    let dataChart = {
        labels: [],
        datasets: [{
            label: '',
            data: [],
            borderColor: 'rgba(29,129,162,0.54)',
            backgroundColor: 'rgb(29,129,162)',
            fill: false,
        }]
    };
    useEffect(() => {
        setDataForChart(null)
        if (car_type === 'pc') {
            getCarsInTheLast5YearsByMonthPC().then((data) => {
                data.data.data.forEach(({year_month, value}) => {
                    dataChart.labels.push(formatMonthInRussian(year_month))
                    dataChart.datasets[0].data.push(value)
                })
                setDataForChart(dataChart)
                setTimeout(()=>{
                    getRefToPdf(ref,'ChartLine5YearByMonthPC')
                },2000)
            })
        }
        if (car_type === 'lcv') {
            getCarsInTheLast5YearsByMonthLCV().then((data) => {
                data.data.data.forEach(({year_month, value}) => {
                    dataChart.labels.push(formatMonthInRussian(year_month))
                    dataChart.datasets[0].data.push(value)
                })
                setDataForChart(dataChart)
                setTimeout(()=>{
                    getRefToPdf(ref,'ChartLine5YearByMonthLCV')
                },2000)
            })
        }
        if (car_type === 'all') {
            getCarsInTheLast5YearsByMonth().then((data) => {
                data.data.data.forEach(({year_month, value}) => {
                    dataChart.labels.push(formatMonthInRussian(year_month))
                    dataChart.datasets[0].data.push(value)
                })
                setDataForChart(dataChart)
                setTimeout(()=>{
                    getRefToPdf(ref,'ChartLine5YearByMonthALL')
                },2000)
            })
        }
        return () => {
            setDataForChart(null)
        }

    }, [car_type])

    return (
        <div ref={ref} className={cls.chartLine}>
            {
                car_type === 'pc'
                    ? <div className={cls.title}>
                        <h2> Рынок легковых автомобилей БАА <br/> <span>Продажи по месяцам за последние 5 лет</span></h2>
                        <div>
                            <i>{getLastMonthDate().toLocaleDateString('ru-RU', {year: 'numeric', month: 'long',})}</i>
                            <span>
                                 <img src={pc} alt="logo"/>
                            </span>

                        </div>
                    </div>
                    : ''
            }
            {
                car_type === 'lcv'
                    ? <div className={cls.title}>
                        <h2> Рынок легких коммерческих автомобилей БАА <br/>
                            <span>Продажи по месяцам за последние 5 лет</span></h2>
                        <div>
                            <i>{getLastMonthDate().toLocaleDateString('ru-RU', {year: 'numeric', month: 'long',})}</i>
                            <span>
                                 <img src={lcv} alt="logo"/>
                            </span>

                        </div>
                    </div>
                    : ''
            }
            {
                car_type === 'all'
                    ? <div className={cls.title}>
                        <h2> Рынок транспортных средств БАА <br/> <span>Продажи по месяцам за последние 5 лет</span></h2>
                        <div>
                            <i>{getLastMonthDate().toLocaleDateString('ru-RU', {year: 'numeric', month: 'long',})}</i>
                            <span>
                                <img src={pc} alt="logo"/>
                                <img src={lcv} alt="logo"/>
                            </span>

                        </div>
                    </div>
                    : ''
            }
            {dataForChart
                ? <Line data={dataForChart} options={{
                    animation: false,
                    scales: {
                        x: {

                            grid: {
                                display: true,
                            },
                            ticks: {
                                autoSkip: false,
                                maxRotation: 90,
                                minRotation: 90,
                                offset: -100,
                                beginAtZero: false,
                                precision: 0,
                                source: 'auto',
                                crossAlign: 'far',
                                align: 'center',
                            },

                        },
                        y: {
                            grid: {
                                display: true, // устанавливаем значение false для отключения сетки по оси Y
                            },
                        },
                    },
                    elements: {
                        point: {
                            radius: 5, // Устанавливаем радиус точек на 0, чтобы скрыть их
                            hitRadius: 10,
                            hoverRadius: 10
                        }
                    },
                    plugins: {
                        tooltip: {
                            displayColors: false,
                            titleColor: '#fff',
                            enabled: true,
                        },
                        datalabels: {
                            display: true,
                            color: 'black',
                            anchor: 'end', // Устанавливаем якорь для меток данных
                            align: 'top', // Устанавливаем выравнивание меток данных
                            offset: 7, // Устанавливаем смещение меток данных вверх
                            font: {
                                size: 14,
                                weight: 'normal'
                            },
                            formatter: function (value, context) {
                                if (context.chart.data.labels[context.dataIndex].split('-')[0] === numericPrevMonth) {
                                    return value;
                                } else {
                                    return ''; // Пустая строка, чтобы скрыть метки для остальных лейблов
                                }
                            },
                            padding: { // Добавляем опцию padding для сдвига меток влево на 5 пикселей
                                left: -5
                            }
                        },
                        legend: {
                            display: false, // Устанавливаем значение true для отображения легенды
                            labels: {
                                display: true // Устанавливаем значение false для скрытия меток датасетов в легенде
                            },
                        },
                        title: {
                            display: true,
                            text: 'ГРАФИК ПРОДАЖ ПО МЕСЯЦАМ ЗА ПОСЛЕДНИЕ 5 ЛЕТ',
                            color: '#fff',
                            font: {
                                size: 24

                            },
                            padding: {
                                bottom: 0
                            }
                        },
                    }
                }}></Line>

                : <div className={cls.spinner_block}><Spinner/></div>
            }

        </div>
    );
};

export default memo(ChartLine5YearByMonth);