import React from 'react';
import {Form} from "react-bootstrap";
import {MONTH_ANALYSIS} from "../../../../../utils/consts";

const SelectYearAnalysis = ({value,setValue}) => {
    return (
        <div>
            <Form style={{display:'flex',gap:'20px',alignItems:'center'}}>
                <Form.Select value={value} onChange={(e)=>setValue(e.target.value)} style={{width:'100%',minWidth:120}} >
                    <option value={''}>Выберите месяц</option>
                    <option value={'1704056400'}>январь</option>
                    <option value={'1706734800'}>февраль</option>
                    <option value={'1709240400'}>март</option>
                    <option value={'1711918800'}>апрель</option>
                    <option value={'1714510800'}>май</option>
                    <option value={'1717189200'}>июнь</option>
                    <option value={'1719781200'}>июль</option>
                    <option value={'1722459600'}>август</option>
                    <option value={'1725138000'}>сентабрь</option>
                    <option value={'1727730000'}>октябрь</option>
                    <option value={'1730408400'}>ноябрь</option>
                    <option value={'1733000400'}>декабрь</option>
                </Form.Select>
            </Form>
        </div>
    );
};

export default SelectYearAnalysis;