import {$authHost} from "./index";

const monthToDigit = {'январь':1,'февраль':2,'март':3,'апрель':4,'май':5,'июнь':6,'июль':7,'август':8,'сентябрь':9,'октябрь':10,'ноябрь':11,'декабрь':12,}

export const getCarsInTheLast5Years = async () => {
    return await $authHost.get(`/server/stat/common?type=year`)
}

export const getCarsInTheLast5YearsPC = async () => {
    return await $authHost.get(`/server/stat/common?type=year&car_type_id=1`)
}

export const getCarsInTheLast5YearsLCV = async () => {
    return await $authHost.get(`/server/stat/common?type=year&car_type_id=2`)
}
export const getCarsInTheLast3Years = async () => {
    return await $authHost.get(`/server/stat/diff-cars?from_year=2021&to_year=2024`)
}

export const getCarsInTheLast3YearsPC = async () => {
    return await $authHost.get(`/server/stat/diff-cars?from_year=2021&to_year=2024&car_type_id=1`)
}

export const getCarsInTheLast3YearsLCV = async () => {
    return await $authHost.get(`/server/stat/diff-cars?from_year=2021&to_year=2024&car_type_id=2`)
}

export const getCarsInTheLast5YearsByMonthPC = async () => {
    return await $authHost.get(`/server/stat/common?type=year_month&car_type_id=1`)
}
export const getCarsInTheLast5YearsByMonthLCV = async () => {
    return await $authHost.get(`/server/stat/common?type=year_month&car_type_id=2`)
}

export const getCarsInTheLast5YearsByMonth = async () => {
    return await $authHost.get(`/server/stat/common?type=year_month`)
}

export const getTop30BrandsCurrentAndPrevYear = async () => {
    return await $authHost.get(`/server/stat/top?type=brand_year&from_year=2023&to_year=2024`)
}

export const getTop30BrandsCurrentAndPrevYearPC = async () => {
    return await $authHost.get(`/server/stat/top?type=brand_year&from_year=2023&to_year=2024&car_type_id=1`)
}

export const getTop30BrandsCurrentAndPrevYearLCV = async () => {
    return await $authHost.get(`/server/stat/top?type=brand_year&from_year=2023&to_year=2024&car_type_id=2`)
}


export const getTop30BrandsCurrentAndPrevMonth = async (month) => {

    console.log(month)
    return await $authHost.get(`/server/stat/top?type=brand_year&from_year=2023&to_year=2024&month=${monthToDigit[month]}`)
}
export const getTop30BrandsCurrentAndPrevMonthPC = async (month) => {
    console.log(month)
    return await $authHost.get(`/server/stat/top?type=brand_year&from_year=2023&to_year=2024&car_type_id=1&month=${monthToDigit[month]}`)
}
export const getTop30BrandsCurrentAndPrevMonthLCV = async (month) => {
    console.log(month)
    return await $authHost.get(`/server/stat/top?type=brand_year&from_year=2023&to_year=2024&car_type_id=2&month=${monthToDigit[month]}`)
}

export const getTop30ModelsCurrentAndPrevYears = async () => {
    return await $authHost.get(`/server/stat/top?type=model_year&from_year=2023&to_year=2024`)
}
export const getTop30ModelsCurrentAndPrevYearsPC = async (month) => {
    return await $authHost.get(`/server/stat/top?type=model_year&from_year=2023&to_year=2024&car_type_id=1`)
}
export const getTop30ModelsCurrentAndPrevYearsLCV = async (month) => {
    return await $authHost.get(`/server/stat/top?type=model_year&from_year=2023&to_year=2024&car_type_id=2`)
}

export const getTop30ModelsCurrentAndPrevByMonth = async (month) => {
    return await $authHost.get(`/server/stat/top?type=model_year&from_year=2023&to_year=2024&month=${monthToDigit[month]}`)
}
export const getTop30ModelsCurrentAndPrevByMonthPC = async (month) => {
    return await $authHost.get(`/server/stat/top?type=model_year&from_year=2023&to_year=2024&car_type_id=1&month=${monthToDigit[month]}`)
}
export const getTop30ModelsCurrentAndPrevByMonthLCV = async (month) => {
    return await $authHost.get(`/server/stat/top?type=model_year&from_year=2023&to_year=2024&car_type_id=2&month=${monthToDigit[month]}`)
}
export const testget = async (month) => {
    return await $authHost.get(`/server/stat/diff-cars?from_year=2024&to_year=2024&car_type_id=1`)
}