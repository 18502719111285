

export const ADMIN_ROUTE = '/admin';
export const LOGIN_ROUTE = '/login';
export const REGISTRATION_ROUTE = '/registration';
export const SHOW_STATISTIC_ROUTE = '/statistic';
export const ALL_STATISTIC_ROUTE = '/all-statistic';
export const SET_STATISTIC_ROUTE = '/set-statistic';
export const STATISTIC_MENU = '/menu-statistic';
export const EDIT_CARS = '/edit-cars';
export const BODY_ROUTE = '/';
export const SUCCESS = '/success';
export const SUCCESS_SEND_STATISTIC = '/success-send-statistic';
export const SUCCESS_SEND_FEEDBACK = '/success-feedback';
export const PROFILE = '/profile';
export const EDIT_PROFILE = '/edit-profile';
export const EDIT_PASSWORD = '/edit-password';
export const SUCCESS_SEND_NEW_PASSWORD = '/success-send-password';
export const SUCCESS_SEND_PERSONAL_DATA = '/success-send-personal-data';


export const DATA_FOR_PDF = '/data-for-pdf';


export const COLORS_COMPARE_BLOCK = ['#b71c1c', '#f44336', '#ffcdd2', '#f5dcb4', '#880e4f', '#e91e63', '#f8bbd0', '#7b1fa2', '#ba68c8', '#1a237e', '#3f51b5', '#2196f3', '#03a9f4', '#00bcd4',
    '#009688', '#388e3c', '#689f38', '#afb42b', '#fbc02d', '#ffa000', '#f57c00', '#3e2723', '#795548', '#000000', '#525252', '#607d8b', '#bf360c', '#263238', '#ffeb3b'];

export const HIGHT_LIGHT_BACKGROUDN_COLOR = '#a5ffa5'


export const CURRENT_YEAR = 1704056400
export const NEXT_YEAR = 1735678800


// export const CURRENT_YEAR_MONTH_2022 = {
//     january: 1640984400,
//     february: 1643662800,
//     march: 1646082000,
//     april: 1648760400,
//     may: 1651352400,
//     june: 1654030800,
//     july: 1656622800,
//     august: 1659301200,
//     september: 1661979600,
//     october: 1664571600,
//     november: 1667250000,
//     december: 1669842000
// }
// export const CURRENT_YEAR_MONTH = {
//     january: '1640984400',
//     february: '1643662800',
//     march: '1646082000',
//     april: '1648760400',
//     may: '1651352400',
//     june: '1654030800',
//     july: '1656622800',
//     august: '1659301200',
//     september: '1661979600',
//     october: '1664571600',
//     november: '1667250000',
//     december: '1669842000'
// }

export const CURRENT_YEAR_MONTH = {
    january: '1704056400',
    february: '1706734800',
    march: '1709240400',
    april: '1711918800',
    may: '1714510800',
    june: '1717189200',
    july: '1719781200',
    august: '1722459600',
    september: '1725138000',
    october: '1727730000',
    november: '1730408400',
    december: '1733000400'
}


export const PREVIOUS_YEAR_MONTH = {
    january: '1672520400',
    february: '1675198800',
    march: '1677618000',
    april: '1680296400',
    may: '1682888400',
    june: '1685566800',
    july: '1688158800',
    august: '1690837200',
    september: '1693515600',
    october: '1696107600',
    november: '1698786000',
    december: '1701378000'
}

export const YEAR_MONTH_2024 = {
    january: '1704056400',
    february: '1706734800',
    march: '1709240400',
    april: '1711918800',
    may: '1714510800',
    june: '1717189200',
    july: '1719781200',
    august: '1722459600',
    september: '1725138000',
    october: '1727730000',
    november: '1730408400',
    december: '1733000400'
}
export const YEAR_MONTH_2023 = {
    january: '1672520400',
    february: '1675198800',
    march: '1677618000',
    april: '1680296400',
    may: '1682888400',
    june: '1685566800',
    july: '1688158800',
    august: '1690837200',
    september: '1693515600',
    october: '1696107600',
    november: '1698786000',
    december: '1701378000'
}

export const YEAR_MONTH_2022 = {
    january: '1640984400',
    february: '1643662800',
    march: '1646082000',
    april: '1648760400',
    may: '1651352400',
    june: '1654030800',
    july: '1656622800',
    august: '1659301200',
    september: '1661979600',
    october: '1664571600',
    november: '1667250000',
    december: '1669842000'
}

export const YEAR_MONTH_2021 = {
    january: '1609448400',
    february: '1612126800',
    march: '1614546000',
    april: '1617224400',
    may: '1619816400',
    june: '1622494800',
    july: '1625086800',
    august: '1627765200',
    september: '1630443600',
    october: '1633035600',
    november: '1635714000',
    december: '1638306000'
}


export const YEAR_MONTH_2020 = {
    january: '1577826000',
    february: '1580504400',
    march: '1583010000',
    april: '1585688400',
    may: '1588280400',
    june: '1590958800',
    july: '1593550800',
    august: '1596229200',
    september: '1598907600',
    october: '1601499600',
    november: '1604178000',
    december: '1606770000'
}

export const YEAR_MONTH_2019 = {
    january: '1546290000',
    february: '1548968400',
    march: '1551387600',
    april: '1554066000',
    may: '1556658000',
    june: '1559336400',
    july: '1561928400',
    august: '1564606800',
    september: '1567285200',
    october: '1569877200',
    november: '1572555600',
    december: '1575147600'
}

// export const MONTH_OBJECT={
//     '1640984400':1609448400,
//     '1643662800':1612126800,
//     '1646082000':1614546000,
//     '1648760400':1617224400,
//     '1651352400':1619816400,
//     '1654030800':1622494800,
//     '1656622800':1625086800,
//     '1659301200':1627765200,
//     '1661979600':1630443600,
//     '1664571600':1633035600,
//     '1667250000':1635714000,
//     '1669842000':1638306000
// }
export const MONTH_OBJECT={
    '1704056400':1672520400,
    '1706734800':1675198800,
    '1709240400':1677618000,
    '1711918800':1680296400,
    '1714510800':1682888400,
    '1717189200':1685566800,
    '1719781200':1688158800,
    '1722459600':1690837200,
    '1725138000':1693515600,
    '1727730000':1696107600,
    '1730408400':1698786000,
    '1733000400':1701378000,
}


export const MONTH_ANALYSIS = 1659301200