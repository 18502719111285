import React from 'react';

import cls from './TitleChartBlock.module.scss'

const TitleChartBlock = ({type,...props}) => {
    return (
        <>
            {
                type === 'pc' && <div {...props} className={cls.title}>
                    <h1>Рынок легковых автомобилей БАА </h1>
                </div>
            }
            {
                type === 'lcv' && <div {...props} className={cls.title}>
                    <h1>Рынок легких коммерческих автомобилей БАА</h1>
                </div>
            }
            {
                type === 'all' && <div {...props} className={cls.title}>
                    <h1>Рынок транспортных средств БАА</h1>
                </div>
            }

        </>

    );
};

export default TitleChartBlock;