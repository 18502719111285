import React, {memo, useEffect, useRef, useState} from 'react';
import {Bar} from "react-chartjs-2";
import cls from './ChartBar3YearModels.module.scss'
import {
    getCarsInTheLast3Years, getCarsInTheLast3YearsLCV, getCarsInTheLast3YearsPC,
    getCarsInTheLast5Years,
    getCarsInTheLast5YearsLCV,
    getCarsInTheLast5YearsPC
} from "../../../http/dataForPdf";
import Spinner from "../../Ui/Spinner/Spinner";
import pc from "../../../pc.png";
import lcv from "../../../lcv.png";


function formatMonthInRussian(dateString) {
    let [year, month] = dateString.split("-");
    let date = new Date(parseInt(year), parseInt(month) - 1);
    let dateFormatter = new Intl.DateTimeFormat('ru-RU', {month: 'long'});
    let monthInRussian = dateFormatter.format(date);
    return monthInRussian.toLowerCase() + "-" + year;
}

const ChartBar3YearModels = ({numericPrevMonth,getRefToPdf, getLastMonthDate, car_type, ...props}) => {
    const [dataForChart, setDataForChart] = useState(null)
    const ref =useRef(null)
    let dataChart = {
        labels: [],
        datasets: [{
            label: 'Данные',
            data: [],
            backgroundColor: [],
            fill: false,
            axis: 'x'
        }]
    };
    useEffect(() => {
        setDataForChart(null)
        if (car_type === 'all') {
            getCarsInTheLast3Years().then((data) => {
                data.data.data.forEach(({year_month, value}) => {
                    dataChart.labels.push(formatMonthInRussian(year_month))
                    dataChart.datasets[0].data.push(value)
                })
                const backgroundColor = dataChart.labels.map((label) => (label.split('-')[0]=== numericPrevMonth ? 'rgb(29,129,162)' : 'rgba(14,81,103,0.45)'));
                dataChart.datasets[0].backgroundColor = backgroundColor;
                setDataForChart(dataChart)
                setTimeout(()=>{
                    getRefToPdf(ref,'ChartBar3YearModelsALL')
                },2000)
            })
        }
        if (car_type === 'lcv') {
            getCarsInTheLast3YearsLCV().then((data) => {
                data.data.data.forEach(({year_month, value}) => {
                    dataChart.labels.push(formatMonthInRussian(year_month))
                    dataChart.datasets[0].data.push(value)
                })
                const backgroundColor = dataChart.labels.map((label) => (label.split('-')[0]=== numericPrevMonth ? 'rgb(29,129,162)' : 'rgba(14,81,103,0.45)'));
                dataChart.datasets[0].backgroundColor = backgroundColor;
                setDataForChart(dataChart)
                setTimeout(()=>{
                    getRefToPdf(ref,'ChartBar3YearModelsLCV')
                },2000)
            })
        }
        if (car_type === 'pc') {
            getCarsInTheLast3YearsPC().then((data) => {

                data.data.data.forEach(({year_month, value}) => {
                    dataChart.labels.push(formatMonthInRussian(year_month))
                    dataChart.datasets[0].data.push(value)
                })
                const backgroundColor = dataChart.labels.map((label) => (label.split('-')[0]=== numericPrevMonth ? 'rgb(29,129,162)' : 'rgba(14,81,103,0.45)'));
                dataChart.datasets[0].backgroundColor = backgroundColor;
                setDataForChart(dataChart)
                setTimeout(()=>{
                    getRefToPdf(ref,'ChartBar3YearModelsPC')
                },2000)
            })
        }
        return () => {
            setDataForChart(null)
        }
    }, [car_type])


    return (
        <div ref={ref}>
            {
                car_type === 'pc'
                    ? <div className={cls.title}>
                        <h2> Рынок легковых автомобилей БАА <br/> <span>Количество продаваемых моделей на рынке Беларуси, 3 года</span>
                        </h2>
                        <div>
                            <i>{getLastMonthDate().toLocaleDateString('ru-RU', {year: 'numeric', month: 'long',})}</i>
                            <span>
                                 <img src={pc} alt="logo"/>
                            </span>

                        </div>
                    </div>
                    : ''
            }
            {
                car_type === 'lcv'
                    ? <div className={cls.title}>
                        <h2> Рынок легких коммерческих автомобилей БАА <br/> <span>Количество продаваемых моделей на рынке Беларуси, 3 года</span>
                        </h2>
                        <div>
                            <i>{getLastMonthDate().toLocaleDateString('ru-RU', {year: 'numeric', month: 'long',})}</i>
                            <span>
                                 <img src={lcv} alt="logo"/>
                            </span>

                        </div>
                    </div>
                    : ''
            }
            {
                car_type === 'all'
                    ? <div className={cls.title}>
                        <h2> Рынок транспортных средств БАА <br/> <span>Количество продаваемых моделей на рынке Беларуси, 3 года</span>
                        </h2>
                        <div>
                            <i>{getLastMonthDate().toLocaleDateString('ru-RU', {year: 'numeric', month: 'long',})}</i>
                            <span>
                                <img src={pc} alt="logo"/>
                                <img src={lcv} alt="logo"/>
                            </span>

                        </div>
                    </div>
                    : ''
            }
            {dataForChart
                ? <div className={cls.chartBlock}>
                    <Bar {...props} options={{
                        animation: false,
                        scales: {
                            x: {
                                grid: {
                                    display: true, // устанавливаем значение false для отключения сетки по оси X
                                },
                                ticks: {
                                    autoSkip: false,
                                    maxRotation: 90,
                                    minRotation: 90,
                                    offset: -100,
                                    beginAtZero: false,
                                    precision: 0,
                                    source: 'auto',
                                    crossAlign: 'far',
                                    align: 'center',
                                },
                            },
                            // y: {
                            //     grid: {
                            //         display: true, // устанавливаем значение false для отключения сетки по оси Y
                            //     },
                            // },
                        },

                        plugins: {
                            tooltip: {
                                displayColors: false,
                                titleColor: '#fff',
                                enabled: true,
                            },
                            datalabels: {
                                display: true,
                                color: 'black',
                                anchor: 'end', // Устанавливаем якорь для меток данных
                                align: 'top', // Устанавливаем выравнивание меток данных
                                offset: 7, // Устанавливаем смещение меток данных вверх
                                font: {
                                    size: 14,
                                    weight: 'normal'
                                },
                                formatter: function (value, context) {
                                    if (context.chart.data.labels[context.dataIndex].split('-')[0] === numericPrevMonth) {
                                        return value;
                                    } else {
                                        return ''; // Пустая строка, чтобы скрыть метки для остальных лейблов
                                    }
                                },
                                padding: { // Добавляем опцию padding для сдвига меток влево на 5 пикселей
                                    left: -5
                                }
                            },
                            legend: {
                                display: true,
                                labels: {
                                    font: {
                                        size: 0
                                    },

                                },
                                title: {
                                    display: false,
                                    text: 'Продажи за последние 5 лет',
                                    color: 'goldenrod',
                                    font: {
                                        size: 24
                                    }
                                },
                            }
                        }
                    }} data={dataForChart}/>
                </div>

                : <div className={cls.spinner_block1}>
                    <Spinner/>
                </div>
            }
        </div>


    );
};

export default memo(ChartBar3YearModels);