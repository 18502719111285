import React from 'react';
import MyPdfPage from "./MyPdfPage";
import {Image, StyleSheet, Text, View} from "@react-pdf/renderer";
import pcIcon from "../../../pc.png";
import lcvIcon from "../../../lcv.png";

const stylesPdf = StyleSheet.create({
    page: {fontFamily: "Roboto"},
    flex: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        marginBottom: 5,
        justifyContent: 'center',
        alignItem: 'center'
    },
    pageNumbers: {
        position: 'absolute',
        bottom: 20,
        left: 0,
        right: 0,
        textAlign: 'center'
    },
});

const MyFrontPage = ({type}) => {
    return (
        <>
            {
                type ==='pc' &&
                <MyPdfPage style={{justifyContent: 'center'}}>
                    <Text style={{ textAlign: 'center', fontSize:30}}>Рынок легковых автомобилей</Text>
                    <Text style={{ textAlign: 'center', fontSize:30}}>БАА</Text>
                    <Image
                        src={pcIcon}
                        style={{maxWidth:'70px', display: 'block', margin:'0 0 0 330px' }}
                    />
                </MyPdfPage>
            }
            {
                type ==='lcv' &&
                <MyPdfPage style={{justifyContent: 'center'}}>
                    <Text style={{ textAlign: 'center', fontSize:30}}>Рынок легких коммерческих автомобилей</Text>
                    <Text style={{ textAlign: 'center', fontSize:30}}>БАА</Text>
                    <Image
                        src={lcvIcon}
                        style={{maxWidth:'70px', display: 'block', margin:'0 0 0 330px' }}
                    />
                </MyPdfPage>
            }
            {
                type ==='all' &&
                <MyPdfPage style={{justifyContent: 'center'}}>
                    <Text style={{ textAlign: 'center', fontSize:30}}>Рынок транспортных средств</Text>
                    <Text style={{ textAlign: 'center', fontSize:30}}>БАА</Text>
                    <View style={stylesPdf.flex}>
                        <Image
                            src={pcIcon}
                            style={{maxWidth:'70px', display: 'block', }}
                        />
                        <Image
                            src={lcvIcon}
                            style={{maxWidth:'70px', display: 'block', }}
                        />
                    </View>
                </MyPdfPage>
            }
        </>
    );
};

export default MyFrontPage;