import React from 'react';
import {Font, Image, StyleSheet, Text} from "@react-pdf/renderer";
import logo from "../../../logo.png";

Font.register({
    family: "Roboto",
    src:
        "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-medium-webfont.ttf"
});
const stylesPdf = StyleSheet.create({
    page:{fontFamily : "Roboto"},
    flex:{
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        marginBottom: 5,
        justifyContent: 'center',
        alignItem:'center'
    },
    pageNumbers: {
        position: 'absolute',
        bottom: 20,
        left: 0,
        right: 0,
        textAlign: 'center'
    },
    date: {
        position: 'absolute',
        bottom: 20,
        right: 10,
        fontsize:14,
        color:'goldenrod'

    },
});
function getLastMonthDate() {
    const currentDate = new Date();
    const lastMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1);
    lastMonth.setFullYear(currentDate.getMonth() === 0 ? currentDate.getFullYear() - 1 : currentDate.getFullYear());
    return lastMonth.toLocaleDateString('ru-RU', {year: 'numeric', month: 'long',});
}

const PdfPageFooter = () => {

    return (
        <>
            <Image src={logo} style={{maxWidth:70,  position: 'absolute',
                bottom: 10,
                left: 10,
                right: 0,}}/>
            <Text style={stylesPdf.pageNumbers} render={({ pageNumber, totalPages }) => (
                `${pageNumber} / ${totalPages}`
            )} fixed />
            <Text style={stylesPdf.date}>{getLastMonthDate()}</Text>
        </>
    );
};

export default PdfPageFooter;