import React, {memo, useEffect, useRef, useState} from 'react';
import cls from './DataForPdf.module.scss'
import {Chart} from "react-chartjs-2";
import ChartBar5Year from "../components/DataForPdf/ChartBar5Year/ChartBar5Year";

import ChartDataLabels from "chartjs-plugin-datalabels";
import ChartLine5YearByMonth from "../components/DataForPdf/ChartLine5YearByMonth/ChartLine5YearByMonth";
import ChartBarTop30Brand from "../components/DataForPdf/ChartBarTop30Brand/ChartBarTop30Brand";
import ChartBarTop30Models from "../components/DataForPdf/ChartBarTop30Models/ChartBarTop30Models";
import ChartBarTop30BrandByMonth from "../components/DataForPdf/ChartBarTop30BrandByMonth/ChartBarTop30BrandByMonth";
import TitleChartBlock from "../components/DataForPdf/TitleChartBlock/TitleChartBlock";
import ChartBarTop30ModelsByMonth from "../components/DataForPdf/ChartBarTop30ModelsByMonth/ChartBarTop30ModelsByMonth";
import ChartBar3YearModels from "../components/DataForPdf/ChartBar3YearModels/ChartBar3YearModels";
import {Document, Font, Image, PDFDownloadLink, PDFViewer, StyleSheet, Text} from "@react-pdf/renderer";
import MyPdfPage from "../components/DataForPdf/PdfComponents/MyPdfPage";
import html2canvas from "html2canvas";
import MyFrontPage from "../components/DataForPdf/PdfComponents/MyFrontPage";

Font.register({
    family: "Roboto",
    src:
        "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-medium-webfont.ttf"
});
const stylesPdf = StyleSheet.create({
    page: {fontFamily: "Roboto"},
    flex: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        marginBottom: 5,
        justifyContent: 'center',
        alignItem: 'center'
    },
    pageNumbers: {
        position: 'absolute',
        bottom: 20,
        left: 0,
        right: 0,
        textAlign: 'center'
    },
});

const DataForPdf = () => {
    Chart.register(ChartDataLabels)

    const [pdfDone, setPdfDone] = useState(false)


    const [imageChartBar5YearAll, setImageChartBar5YearAll] = useState(null)
    const [imageChartBar5YearPC, setImageChartBar5YearPC] = useState(null)
    const [imageChartBar5YearLCV, setImageChartBar5YearLCV] = useState(null)

    const [imageChartLine5YearByMonthAll, setImageChartLine5YearByMonthAll] = useState(null)
    const [imageChartLine5YearByMonthPC, setImageChartLine5YearByMonthPC] = useState(null)
    const [imageChartLine5YearByMonthLCV, setImageChartLine5YearByMonthLCV] = useState(null)

    const [imageChartBar3YearModelsALL, setImageChartBar3YearModelsALL] = useState(null)
    const [imageChartBar3YearModelsPC, setImageChartBar3YearModelsPC] = useState(null)
    const [imageChartBar3YearModelsLCV, setImageChartBar3YearModelsLCV] = useState(null)

    const [imageChartBarTop30BrandALL, setImageChartBarTop30BrandALL] = useState(null)
    const [imageChartBarTop30BrandPC, setImageChartBarTop30BrandPC] = useState(null)
    const [imageChartBarTop30BrandLCV, setImageChartBarTop30BrandLCV] = useState(null)

    const [imageChartBarTop30ModelsALL, setImageChartBarTop30ModelsALL] = useState(null)
    const [imageChartBarTop30ModelsPC, setImageChartBarTop30ModelsPC] = useState(null)
    const [imageChartBarTop30ModelsLCV, setImageChartBarTop30ModelsLCV] = useState(null)

    const [imageChartBarTop30BrandByMonthALL, setImageChartBarTop30BrandByMonthALL] = useState(null)
    const [imageChartBarTop30BrandByMonthPC, setImageChartBarTop30BrandByMonthPC] = useState(null)
    const [imageChartBarTop30BrandByMonthLCV, setImageChartBarTop30BrandByMonthLCV] = useState(null)

    const [imageChartBarTop30ModelsByMonthALL, setImageChartBarTop30ModelsByMonthALL] = useState(null)
    const [imageChartBarTop30ModelsByMonthPC, setImageChartBarTop30ModelsByMonthPC] = useState(null)
    const [imageChartBarTop30ModelsByMonthLCV, setImageChartBarTop30ModelsByMonthLCV] = useState(null)

    const dateFormatter = new Intl.DateTimeFormat('ru-RU', {month: 'long'});
    const numericPrevMonth = dateFormatter.format(getLastMonthDate())

    function getLastMonthDate() {
        const currentDate = new Date();
        const lastMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1);
        lastMonth.setFullYear(currentDate.getMonth() === 0 ? currentDate.getFullYear() - 1 : currentDate.getFullYear());
        return lastMonth;
    }

    const exportAsImage = async (element, chart_name) => {
        const canvas = await html2canvas(element);
        const image = canvas.toDataURL("image/png", 1.0);
        switch (chart_name) {
            case 'ChartBar5YearPC':
                setTimeout(() => {
                    setImageChartBar5YearPC(image)
                }, 1000)

                break;
            case 'ChartBar5YearLCV':
                setTimeout(() => {
                    setImageChartBar5YearLCV(image)
                }, 1000)

                break;
            case 'ChartBar5YearALL':
                setTimeout(() => {
                    setImageChartBar5YearAll(image)
                }, 1000)

                break;

            case 'ChartLine5YearByMonthPC':
                setTimeout(() => {
                    setImageChartLine5YearByMonthPC(image)
                }, 1000)

                break;
            case 'ChartLine5YearByMonthLCV':
                setTimeout(() => {
                    setImageChartLine5YearByMonthLCV(image)
                }, 1000)

                break;
            case 'ChartLine5YearByMonthALL':
                setTimeout(() => {
                    setImageChartLine5YearByMonthAll(image)
                }, 1000)

                break;

            case 'ChartBar3YearModelsALL':
                setTimeout(() => {
                    setImageChartBar3YearModelsALL(image)
                }, 1000)

                break;
            case 'ChartBar3YearModelsLCV':
                setTimeout(() => {
                    setImageChartBar3YearModelsLCV(image)
                }, 1000)

                break;
            case 'ChartBar3YearModelsPC':
                setTimeout(() => {
                    setImageChartBar3YearModelsPC(image)
                }, 1000)

                break;

            case 'ChartBarTop30BrandALL':
                setTimeout(() => {
                    setImageChartBarTop30BrandALL(image)
                }, 1000)

                break;
            case 'ChartBarTop30BrandPC':
                setTimeout(() => {
                    setImageChartBarTop30BrandPC(image)
                }, 1000)

                break;
            case 'ChartBarTop30BrandLCV':
                setTimeout(() => {
                    setImageChartBarTop30BrandLCV(image)
                }, 1000)

                break;

            case 'ChartBarTop30ModelsALL':
                setTimeout(() => {
                    setImageChartBarTop30ModelsALL(image)
                }, 1000)

                break;
            case 'ChartBarTop30ModelsPC':
                setTimeout(() => {
                    setImageChartBarTop30ModelsPC(image)
                }, 1000)

                break;
            case 'ChartBarTop30ModelsLCV':
                setTimeout(() => {
                    setImageChartBarTop30ModelsLCV(image)
                }, 1000)

                break;

            case 'ChartBarTop30BrandByMonthALL':
                setTimeout(() => {
                    setImageChartBarTop30BrandByMonthALL(image)
                }, 1000)

                break;
            case 'ChartBarTop30BrandByMonthPC':
                setTimeout(() => {
                    setImageChartBarTop30BrandByMonthPC(image)
                }, 1000)

                break;
            case 'ChartBarTop30BrandByMonthLCV':
                setTimeout(() => {
                    setImageChartBarTop30BrandByMonthLCV(image)
                }, 1000)

                break;

            case 'ChartBarTop30ModelsByMonthALL':
                setTimeout(() => {
                    setImageChartBarTop30ModelsByMonthALL(image)
                }, 1000)

                break;
            case 'ChartBarTop30ModelsByMonthPC':
                setTimeout(() => {
                    setImageChartBarTop30ModelsByMonthPC(image)
                }, 1000)

                break;
            case 'ChartBarTop30ModelsByMonthLCV':
                setTimeout(() => {
                    setImageChartBarTop30ModelsByMonthLCV(image)
                }, 1000)

                break;
            default:
                break
        }
    };
    useEffect(() => {
        if (imageChartBar5YearPC && imageChartLine5YearByMonthPC && imageChartBar3YearModelsPC
            && imageChartBarTop30BrandPC && imageChartBarTop30ModelsPC && imageChartBarTop30BrandByMonthPC &&
            imageChartBarTop30ModelsByMonthPC && imageChartBar5YearLCV && imageChartLine5YearByMonthLCV && imageChartBar3YearModelsLCV
            && imageChartBarTop30BrandLCV && imageChartBarTop30ModelsLCV && imageChartBarTop30BrandByMonthLCV &&
            imageChartBarTop30ModelsByMonthLCV && imageChartBar5YearAll && imageChartLine5YearByMonthAll &&
            imageChartBar3YearModelsALL && imageChartBarTop30BrandALL && imageChartBarTop30ModelsALL && imageChartBarTop30BrandByMonthALL &&
            imageChartBarTop30ModelsByMonthALL) {
            setPdfDone(true)
        }

    }, [imageChartBar5YearPC, imageChartLine5YearByMonthPC, imageChartBar3YearModelsPC, imageChartBarTop30BrandPC,
        imageChartBarTop30ModelsPC, imageChartBarTop30BrandByMonthPC, imageChartBarTop30ModelsByMonthPC, imageChartBar5YearLCV,
        imageChartLine5YearByMonthLCV, imageChartBar3YearModelsLCV, imageChartBarTop30BrandLCV, imageChartBarTop30ModelsLCV,
        imageChartBarTop30BrandByMonthLCV, imageChartBarTop30ModelsByMonthLCV, imageChartBar5YearAll, imageChartLine5YearByMonthAll,
        imageChartBar3YearModelsALL, imageChartBarTop30BrandALL, imageChartBarTop30ModelsALL, imageChartBarTop30BrandByMonthALL,
        imageChartBarTop30ModelsByMonthALL])


    const getRefToPdf = (ref, chart_name) => {
        exportAsImage(ref.current, chart_name)

    }


    return (
        <div className={['container', cls.container].join(' ')}>
            {
                pdfDone
                    ? <div style={{display: 'flex'}}><PDFDownloadLink style={{
                        width: '100%',
                        background: 'red',
                        padding: '10px 0 0 0',
                        color: '#fff',
                        textDecoration: 'none',
                        textAlign: 'center',
                        fontWeight: 'bold'
                    }} document={
                        <Document>
                            <MyFrontPage type={'pc'}/>
                            <MyPdfPage>
                                <Image src={imageChartBar5YearPC}/>
                            </MyPdfPage>
                            <MyPdfPage>
                                <Image src={imageChartLine5YearByMonthPC}/>
                            </MyPdfPage>
                            <MyPdfPage>
                                <Image src={imageChartBar3YearModelsPC}/>
                            </MyPdfPage>
                            <MyPdfPage>
                                <Image src={imageChartBarTop30BrandPC}/>
                            </MyPdfPage>
                            <MyPdfPage>
                                <Image src={imageChartBarTop30ModelsPC}/>
                            </MyPdfPage>
                            <MyPdfPage>
                                <Image src={imageChartBarTop30BrandByMonthPC}/>
                            </MyPdfPage>
                            <MyPdfPage>
                                <Image src={imageChartBarTop30ModelsByMonthPC}/>
                            </MyPdfPage>

                            <MyFrontPage type={'lcv'}/>
                            <MyPdfPage>
                                <Image src={imageChartBar5YearLCV}/>
                            </MyPdfPage>
                            <MyPdfPage>
                                <Image src={imageChartLine5YearByMonthLCV}/>
                            </MyPdfPage>
                            <MyPdfPage>
                                <Image src={imageChartBar3YearModelsLCV}/>
                            </MyPdfPage>
                            <MyPdfPage>
                                <Image src={imageChartBarTop30BrandLCV}/>
                            </MyPdfPage>
                            <MyPdfPage>
                                <Image src={imageChartBarTop30ModelsLCV}/>
                            </MyPdfPage>
                            <MyPdfPage>
                                <Image src={imageChartBarTop30BrandByMonthLCV}/>
                            </MyPdfPage>
                            <MyPdfPage>
                                <Image src={imageChartBarTop30ModelsByMonthLCV}/>
                            </MyPdfPage>

                            <MyFrontPage type={'all'}/>
                            <MyPdfPage>
                                <Image src={imageChartBar5YearAll}/>
                            </MyPdfPage>
                            <MyPdfPage>
                                <Image src={imageChartLine5YearByMonthAll}/>
                            </MyPdfPage>
                            <MyPdfPage>
                                <Image src={imageChartBar3YearModelsALL}/>
                            </MyPdfPage>
                            <MyPdfPage>
                                <Image src={imageChartBarTop30BrandALL}/>
                            </MyPdfPage>
                            <MyPdfPage>
                                <Image src={imageChartBarTop30ModelsALL}/>
                            </MyPdfPage>
                            <MyPdfPage>
                                <Image src={imageChartBarTop30BrandByMonthALL}/>
                            </MyPdfPage>
                            <MyPdfPage>
                                <Image src={imageChartBarTop30ModelsByMonthALL}/>
                            </MyPdfPage>
                        </Document>
                    } fileName="chart.pdf">
                        СКАЧАТЬ
                        <p style={{fontSize:10, color:'#fff'}}>данные предоставлены на {new Date().toLocaleDateString('ru')}</p>
                    </PDFDownloadLink>
                    </div>
                    : ''
            }


            <TitleChartBlock type={'pc'}
                             date={getLastMonthDate().toLocaleDateString('ru-RU', {year: 'numeric', month: 'long',})}/>
            <div className={cls.padding}>
                <ChartBar5Year getRefToPdf={getRefToPdf} car_type={'pc'} getLastMonthDate={getLastMonthDate}/>
            </div>
            <div className={cls.padding}>
                <ChartLine5YearByMonth getRefToPdf={getRefToPdf} car_type={'pc'} getLastMonthDate={getLastMonthDate}
                                       numericPrevMonth={numericPrevMonth}/>
            </div>
            <div className={cls.padding}>
                <ChartBar3YearModels getRefToPdf={getRefToPdf} car_type={'pc'} getLastMonthDate={getLastMonthDate}
                                     numericPrevMonth={numericPrevMonth}/>
            </div>
            <div className={cls.padding}>
                <ChartBarTop30Brand getRefToPdf={getRefToPdf} getLastMonthDate={getLastMonthDate} car_type={'pc'}/>
            </div>
            <div className={cls.padding}>
                <ChartBarTop30Models getRefToPdf={getRefToPdf} getLastMonthDate={getLastMonthDate} car_type={'pc'}/>
            </div>
            <div className={cls.padding}>
                <ChartBarTop30BrandByMonth getRefToPdf={getRefToPdf} getLastMonthDate={getLastMonthDate}
                                           numericPrevMonth={numericPrevMonth}
                                           car_type={'pc'}/>
            </div>
            <div className={cls.padding}>
                <ChartBarTop30ModelsByMonth getRefToPdf={getRefToPdf} getLastMonthDate={getLastMonthDate}
                                            car_type={'pc'}
                                            numericPrevMonth={numericPrevMonth}/>
            </div>

            <TitleChartBlock type={'lcv'}
                             date={getLastMonthDate().toLocaleDateString('ru-RU', {year: 'numeric', month: 'long',})}/>
            <div className={cls.padding}>
                <ChartBar5Year getRefToPdf={getRefToPdf} car_type={'lcv'} getLastMonthDate={getLastMonthDate}/>
            </div>
            <div className={cls.padding}>
                <ChartLine5YearByMonth getRefToPdf={getRefToPdf} car_type={'lcv'} getLastMonthDate={getLastMonthDate}
                                       numericPrevMonth={numericPrevMonth}/>
            </div>
            <div className={cls.padding}>
                <ChartBar3YearModels getRefToPdf={getRefToPdf} car_type={'lcv'} getLastMonthDate={getLastMonthDate}
                                     numericPrevMonth={numericPrevMonth}/>
            </div>
            <div className={cls.padding}>
                <ChartBarTop30Brand getRefToPdf={getRefToPdf} getLastMonthDate={getLastMonthDate} car_type={'lcv'}/>
            </div>
            <div className={cls.padding}>
                <ChartBarTop30Models getRefToPdf={getRefToPdf} getLastMonthDate={getLastMonthDate} car_type={'lcv'}/>
            </div>
            <div className={cls.padding}>
                <ChartBarTop30BrandByMonth getRefToPdf={getRefToPdf} getLastMonthDate={getLastMonthDate}
                                           numericPrevMonth={numericPrevMonth}
                                           car_type={'lcv'}/>
            </div>
            <div className={cls.padding}>
                <ChartBarTop30ModelsByMonth getRefToPdf={getRefToPdf} getLastMonthDate={getLastMonthDate}
                                            car_type={'lcv'}
                                            numericPrevMonth={numericPrevMonth}/>
            </div>

            <TitleChartBlock type={'all'}
                             date={getLastMonthDate().toLocaleDateString('ru-RU', {year: 'numeric', month: 'long',})}/>
            <div className={cls.padding}>
                <ChartBar5Year getRefToPdf={getRefToPdf} car_type={'all'} getLastMonthDate={getLastMonthDate}/>
            </div>
            <div className={cls.padding}>
                <ChartLine5YearByMonth getRefToPdf={getRefToPdf} car_type={'all'} getLastMonthDate={getLastMonthDate}
                                       numericPrevMonth={numericPrevMonth}/>
            </div>
            <div className={cls.padding}>
                <ChartBar3YearModels getRefToPdf={getRefToPdf} car_type={'all'} getLastMonthDate={getLastMonthDate}
                                     numericPrevMonth={numericPrevMonth}/>
            </div>
            <div className={cls.padding}>
                <ChartBarTop30Brand getRefToPdf={getRefToPdf} getLastMonthDate={getLastMonthDate} car_type={'all'}/>
            </div>
            <div className={cls.padding}>
                <ChartBarTop30Models getRefToPdf={getRefToPdf} getLastMonthDate={getLastMonthDate} car_type={'all'}/>
            </div>
            <div className={cls.padding}>
                <ChartBarTop30BrandByMonth getRefToPdf={getRefToPdf} getLastMonthDate={getLastMonthDate}
                                           numericPrevMonth={numericPrevMonth}
                                           car_type={'all'}/>
            </div>
            <div className={cls.padding}>
                <ChartBarTop30ModelsByMonth getRefToPdf={getRefToPdf} getLastMonthDate={getLastMonthDate}
                                            car_type={'all'}
                                            numericPrevMonth={numericPrevMonth}/>
            </div>
            {
                pdfDone
                    ? <div style={{display: 'flex'}}><PDFDownloadLink style={{
                        width: '100%',
                        background: 'red',
                        padding: '10px 0 0 0',
                        color: '#fff',
                        textDecoration: 'none',
                        textAlign: 'center',
                        fontWeight: 'bold'
                    }} document={
                        <Document>
                            <MyFrontPage type={'pc'}/>
                            <MyPdfPage>
                                <Image src={imageChartBar5YearPC}/>
                            </MyPdfPage>
                            <MyPdfPage>
                                <Image src={imageChartLine5YearByMonthPC}/>
                            </MyPdfPage>
                            <MyPdfPage>
                                <Image src={imageChartBar3YearModelsPC}/>
                            </MyPdfPage>
                            <MyPdfPage>
                                <Image src={imageChartBarTop30BrandPC}/>
                            </MyPdfPage>
                            <MyPdfPage>
                                <Image src={imageChartBarTop30ModelsPC}/>
                            </MyPdfPage>
                            <MyPdfPage>
                                <Image src={imageChartBarTop30BrandByMonthPC}/>
                            </MyPdfPage>
                            <MyPdfPage>
                                <Image src={imageChartBarTop30ModelsByMonthPC}/>
                            </MyPdfPage>

                            <MyFrontPage type={'lcv'}/>
                            <MyPdfPage>
                                <Image src={imageChartBar5YearLCV}/>
                            </MyPdfPage>
                            <MyPdfPage>
                                <Image src={imageChartLine5YearByMonthLCV}/>
                            </MyPdfPage>
                            <MyPdfPage>
                                <Image src={imageChartBar3YearModelsLCV}/>
                            </MyPdfPage>
                            <MyPdfPage>
                                <Image src={imageChartBarTop30BrandLCV}/>
                            </MyPdfPage>
                            <MyPdfPage>
                                <Image src={imageChartBarTop30ModelsLCV}/>
                            </MyPdfPage>
                            <MyPdfPage>
                                <Image src={imageChartBarTop30BrandByMonthLCV}/>
                            </MyPdfPage>
                            <MyPdfPage>
                                <Image src={imageChartBarTop30ModelsByMonthLCV}/>
                            </MyPdfPage>

                            <MyFrontPage type={'all'}/>
                            <MyPdfPage>
                                <Image src={imageChartBar5YearAll}/>
                            </MyPdfPage>
                            <MyPdfPage>
                                <Image src={imageChartLine5YearByMonthAll}/>
                            </MyPdfPage>
                            <MyPdfPage>
                                <Image src={imageChartBar3YearModelsALL}/>
                            </MyPdfPage>
                            <MyPdfPage>
                                <Image src={imageChartBarTop30BrandALL}/>
                            </MyPdfPage>
                            <MyPdfPage>
                                <Image src={imageChartBarTop30ModelsALL}/>
                            </MyPdfPage>
                            <MyPdfPage>
                                <Image src={imageChartBarTop30BrandByMonthALL}/>
                            </MyPdfPage>
                            <MyPdfPage>
                                <Image src={imageChartBarTop30ModelsByMonthALL}/>
                            </MyPdfPage>
                        </Document>
                    } fileName="chart.pdf">
                        СКАЧАТЬ
                        <p style={{fontSize:10, color:'#fff'}}>данные предоставлены на {new Date().toLocaleDateString('ru')}</p>
                    </PDFDownloadLink>
                    </div>
                    : ''
            }

        </div>
    );
};

export default memo(DataForPdf);