import React from 'react';
import {Font, Image, Page, StyleSheet, Text, View} from "@react-pdf/renderer";
import pcIcon from "../../../pc.png";
import PdfPageFooter from "./PdfPageFooter";
import jwtDecode from "jwt-decode";

Font.register({
    family: "Roboto",
    src:
        "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-medium-webfont.ttf"
});
const stylesPdf = StyleSheet.create({
    page: {
        fontFamily: "Roboto",
        height:600,
        padding:10
    },
    flex: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        marginBottom: 5,
        justifyContent: 'center',
        alignItem: 'center'
    },
    pageNumbers: {
        position: 'absolute',
        bottom: 20,
        left: 0,
        right: 0,
        textAlign: 'center'
    },
    waterMark:{
        position: 'absolute',
        bottom:0,
        left:150,
        textAlign: 'center',
        color:'#fffffb',
        opacity:1,
        fontSize:6

    },
    waterMark2:{
        position: 'absolute',
        top: 0,
        left:30,
        pointerEvents:'none',
        color:'#fffffb',
        opacity:1,
        fontSize:6

    },
    waterMark3:{
        position: 'absolute',
        top: 0,
        right:30,
        color:'#fffffb',
        opacity:1,
        fontSize:6
    },
    waterMark4:{
        position: 'absolute',
        bottom: 0,
        right:150,
        textAlign: 'center',
        color:'#fffffb',
        opacity:1,
        fontSize:6

    },
});

const MyPdfPage = ({children, style = {}, ...props}) => {
    const mark = jwtDecode(localStorage.getItem('token'))
    const hiddenMark =mark.email.replaceAll('@','_')

    return (
        <>
            <Page {...props} style={[stylesPdf.page, style]} size={{width:750,height: 550}}>
                <Text style={stylesPdf.waterMark}>{hiddenMark}</Text>
                <Text style={stylesPdf.waterMark2}>{hiddenMark}</Text>
                <Text style={stylesPdf.waterMark3}>{hiddenMark}</Text>
                <Text style={stylesPdf.waterMark4}>{hiddenMark}</Text>
                <View>
                    {children}
                </View>

                <PdfPageFooter/>
            </Page>
        </>
    );
};

export default MyPdfPage;