import React from 'react';
import cls from './Spinner.module.scss'

const Spinner = () => {
    return (
        <div className={cls['lds-roller']}>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    );
};

export default Spinner;